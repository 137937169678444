<template>
  <div :class="classesCombined" class="text-white relative px-4 py-2" style="">
    <p
      v-observe-visibility="visibilityChanged"
      class="py-2 md:text-xl"
      v-html="text"
    ></p>
  </div>
</template>

<script>
export default {
  name: "InitialLetterWithParagraph",
  props: {
    text: {
      type: String,
    },
    background: {
      type: Array,
    },
    parentBackground: {
      type: String,
    },
  },
  data() {
    return {
      classesCombined: [],
      isVisible: false,
    };
  },
  computed: {
    bgColor: function () {
      return this.background != undefined ? this.background[0] : "";
    },
    bgColorBefore: function () {
      return this.background != undefined
        ? `curve-top-${this.background[0]}`
        : "";
    },
    bgColorAfter: function () {
      return this.background != undefined
        ? `curve-bottom-${this.parentBackground}`
        : "";
    },
  },
  methods: {
    collectClasses() {
      this.classesCombined.push(
        this.bgColor,
        this.bgColorBefore,
        this.bgColorAfter
      );
    },
    /* eslint-disable no-unused-vars */
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
      // console.log(entry);
    },
    /* eslint-enable no-unused-vars */
  },
  created() {
    this.collectClasses();
  },
};
</script>

<style scoped>
p::first-letter {
  font-size: 30px;
  padding-right: 2px;
}

.curved::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: -20px;
  height: 40px;
  border-radius: 50% 50% 0 0;
}

.curved::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -25px;
  height: 35px;
  border-radius: 50% 50% 0 0;
}
</style>