<template>
  <div class="section text-white" :class="wrapperClasses">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FullpageSection",
  components: {},
  props: {
    wrapperClasses: Array,
  },
  data() {
    return {};
  },
};
</script>